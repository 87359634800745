import ReactDOM from 'react-dom';
import React, { Suspense } from 'react';
import { Router } from "@reach/router";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { Provider } from 'react-redux';

import "@babel/polyfill";
import 'whatwg-fetch';

import './i18n';

import store from './store';

import { SnackbarProvider } from 'notistack';

import AboutFAQ from './components/about-faq';
import Session from './components/session';
import UpdatesSignUp from './components/updates-signup';

// TODO do some proper initialisation of the store here instead of calling this init dummy function
// stor.dispatch(init({}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#005fe3',
      main: '#00419c',
      dark: '#00357e'
    },
    secondary: {
      light: '#97ff10',
      main: '#00bb11',
      dark: '#007a0b'
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Suspense fallback={(<div>Loading</div>)}>
      <SnackbarProvider autoHideDuration={5000} maxSnack={3} dense={true} preventDuplicate={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Router>
          <Session path='/login' />
          <UpdatesSignUp path='/' />
          <AboutFAQ path='/about-faq' />
        </Router>
      </SnackbarProvider>
      </Suspense>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
