import { call, put, putResolve, takeLatest } from "redux-saga/effects";

import * as Actions from '../actions/index';

import { api } from '../api';
import { decrypt } from '../crypto';
import { toJson } from '../util';

import * as Req from './request';

function* loginRequest(action) {
  try {
    // split the password into pin and password here
    const split_point = action.payload.password.length - 2;
    const pin = action.payload.password.slice(split_point);
    const password = action.payload.password.slice(0,split_point);
    const resp = yield call(fetch, api['get_vault'], Req.post({ username: action.payload.username, pin: pin }) );
    const resp_json = yield resp.json();
    // if the pin check is successful, decrypt the vault using password
    const user_vault = yield decrypt(action.payload.username, password, new Uint8Array(resp_json.vault.counter), new Uint8Array(resp_json.vault.content));

    if (user_vault.trim()[0] != '{') {
      throw new Exception('Login failed.');
    }
    // perform the login on the ui
    yield put( Actions.direct.session.loginSuccess.action({ username: action.payload.username, pin: pin, password: password, vault: toJson(user_vault) }) );
    yield put( Actions.direct.snackbar.enqueue.action({ clear: true, message: 'Welcome back!', options: { variant: 'success' } }) );
  } catch (e) {
    // if not show some error message
    // yield put({type: Actions.LOGIN_FAIL, payload: { error_msg: e.message } });
    // ,
    // action: key => (
      //     <Button onClick={() => props.closeSnackbar(key)}>dissmiss me</Button>
      // ),
    yield put( Actions.direct.snackbar.enqueue.action({ clear: true, message: 'Login failed.', options: { variant: 'error' } }) );
  }

}

function* loginSaga() {
  yield takeLatest(Actions.complex.session.login.id, loginRequest);
}


export const sagas = [ loginSaga ];
