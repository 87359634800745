import { call, put, select, takeLatest } from "redux-saga/effects";

import * as Actions from '../actions/index';

import { api } from '../api';

import * as Req from './request';

import { getSearchId, getBeFirst, getAboutYou, getUpdateForSelection, getUpdateFull } from './selectors';

// TODO generalise this throw, catch and error display behaviour

function throwNon200Resp(resp) {
  if (resp.status != 200) {
    if (resp.status > 499 && resp.status < 600) {
      throw 'Whooopsie, something went wrong on our side. We have been informed about the problem. Try again or come back later.';
    } else {
      throw 'Whooopsie, something went wrong inside your browser. Check your input and try again.';
    }
  }
  return null;
}

function* confirmationCodeRequest(action) {
  try {
    console.log('inside');
    const resp = yield call(fetch, api['get_verification_code'], Req.post(action.payload) );

    throwNon200Resp(resp);

    const resp_json = yield resp.json();
    if (resp_json != null) {
      yield put(Actions.direct.search.updates.setSignupStep.action({ step: 4 }));
    }
  } catch (e) {
    const msg = e.message != null ? e.message : e;
    yield put(Actions.direct.snackbar.enqueue.action({
      message: msg.startsWith('Whooopsie') ? msg : 'Something went wrong. Check, if your internet connection works.',
      options: { variant: 'error' }
    }));
  }

}

function* updatesSignupRequest(action) {
  try {
    const updateFull = yield select(getUpdateFull);
    const updateForSelection = yield select(getUpdateForSelection);
    const beFirst = yield select(getBeFirst);
    if (!updateFull && !updateForSelection && !beFirst) {
      throw 'Whooopsie, you did not select any updates you would like to receive.';
    }
    let updateSelection = {};
    if (updateFull) {
      updateSelection['updates_on_all'] = true;
    }
    if (updateForSelection) {
      updateSelection['updates_on_search_request'] = yield select(getSearchId);
    }
    if (beFirst) {
      updateSelection['be_first'] = beFirst;
      updateSelection['about'] = yield select(getAboutYou);
    }

    const resp = yield call(fetch, api['verified_updates_signup'], Req.post({...action.payload, ...updateSelection  }) );
    throwNon200Resp(resp);

    const resp_json = yield resp.json();
    if (resp_json != null) {
      yield put(Actions.direct.search.updates.setSignupStep({ step: 5 }));
    }
  } catch (e) {
    const msg = e.message != null ? e.message : e;
    yield put(Actions.direct.snackbar.enqueue.action({
      message: msg.startsWith('Whooopsie') ? msg : 'Something went wrong.',
      options: { variant: 'error' }
    }));
  }

}

function* confirmationCodeSaga() {
  yield takeLatest(Actions.complex.signup.codeRequest.id, confirmationCodeRequest);
}

function* updatesSignupSaga() {
  yield takeLatest(Actions.complex.signup.request.id, updatesSignupRequest);
}

export const sagas = [ confirmationCodeSaga, updatesSignupSaga ];
