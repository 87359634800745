import React from 'react';
import Link from '@material-ui/core/Link';

import { withTranslation } from 'react-i18next';

class LocaleSwitch extends React.Component {
  constructor(props) {
    super(props);
  }

  renderLocale(lang) {
    const { t, i18n } = this.props;
    console.log('render lo ' + lang + i18n.language);
    const cssClass = lang != null && lang === i18n.language ? 'selected' : '';
    return <Link onClick={this.switchClick.bind(this, lang)} variant="outlined" color="primary" className={cssClass}>{t('locale.switch.' + lang)}</Link>;
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div id="app-locale-switch">
        {[this.renderLocale('de'), this.renderLocale('en'), this.renderLocale('sv')]}
      </div>
    );
  }

  switchClick(lang) {
    // this.props.logout();
    const { t, i18n } = this.props;
    i18n.changeLanguage(lang);
    console.log(i18n.language);
  }
}

// export default withTranslation()(withStyles(styles)(LocaleSwitch));
export default withTranslation()(LocaleSwitch);
