import undoable, { excludeAction } from 'redux-undo';

import * as Actions from '../actions/index';

import { actions as SearchActions } from './search';
import { actions as SessionActions } from './session';
import { actions as SnackbarActions } from './snackbar';

const actions = {
  ...SearchActions,
  ...SessionActions,
  ...SnackbarActions
}

const initialState = {
  username: '',
  pin: '',
  password: '',
  notifications: [],
  // selectedChannel: -1,
  selectedChannel: 0,
  updatesSignupStep: 1
};

const rootReducer = (state = initialState, action) => {
  const act = actions[action.type];
  console.log(actions);
  console.log("Payload coming ");
  console.log(action.payload);
  console.log("State before "+action.type);
  console.log(state);
  const newState = act ? act({...state}, action.payload) : state;
  console.log("State after "+action.type);
  console.log(newState);
  return newState;
};

// export default undoable(rootReducer, {filter: excludeAction([
//   Actions.INIT,
//   Actions.ENQUEUE_SNACKBAR, Actions.CLOSE_SNACKBAR, Actions.CLOSE_ALL_SNACKBARS, Actions.REMOVE_SNACKBAR
// ]) });
export default undoable(rootReducer, {filter: excludeAction([Actions.direct.snackbar.enqueue.id]) });
