import React from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from "@reach/router";
import { withTranslation } from 'react-i18next';

import withLoggedOutLayout from './layout-loggedout';

class AboutFAQView extends React.Component {
  constructor(props) {
    super(props);
  }

  renderRoadmapSection(t, translation, prefix='') {
    const translated = t(translation, { returnObjects: true });
    return translated != null && translated != translation ? translated.map(item => <div>{prefix}{item}</div>) : null;
  }

  renderLinkInterpolatedAnswerLine(t, line, hookToTitle) {
    let rawSplit = line.split(']]'); // this results in an array of strings like: [ 'foo[[linkCaption|target', 'bar[[hookX|#hook', ...]

    // if rawSplit has a length of 1, there are no links in the answer string
  return rawSplit.length <= 1 ? rawSplit : rawSplit.map((splitItem, i) => {
      const textAndLink = splitItem.split('[[');
      if (textAndLink.length > 1) {
        const captionAndTarget = textAndLink[1].split('|');
        if (captionAndTarget[1] === '#hook') {
           textAndLink[1] = <Link href={'#'+captionAndTarget[0]} rel="noreferrer">{hookToTitle[captionAndTarget[0]]}</Link>;
         } else {
           textAndLink[1] = <RouterLink to={captionAndTarget[1]}><Button variant="contained" color="secondary" className="inline">{captionAndTarget[0]}</Button></RouterLink>;
         }
        return textAndLink;
      }
      return splitItem;
    });
  }

  renderQA(that, t) {
    const translated = t('faq.qa', { returnObjects: true });
    if (translated == null || translated == 'faq.qa') {
      return null;
    }

    const hookToTitle = {};
    translated.forEach((item, index) => hookToTitle[item.hook] = item.q);

    return translated.map(function (item) {
      const rawAnswer = typeof(item.a) === 'string' ? [ item.a ] : item.a;

      const interpolatedFormatted = rawAnswer.map((line, i) => {
        const linksInterpolated = that.renderLinkInterpolatedAnswerLine(t, line, hookToTitle);
        // linksInterpolated.push(<br />);
        return <p>{linksInterpolated.flat()}</p>;
      });

      const reg = new RegExp('[a-zA-Z0-9_]');
      const qaID = item.hook != null ? item.hook : Array.from(item.q.replace(/\s/g, '_')).map(x => reg.test(x) ? x : '').join('');

      return [
        <div class="request h" id={qaID}>{item.q}</div>,
        <div class="answer" id={qaID + '-answer'}>{interpolatedFormatted}</div>
      ]
    }).flat();
  }

  render() {
    const { t } = this.props;

    return (
      <div id="app-main">
        <RouterLink to="/"><Button variant="contained" color="secondary">{t('about.back')}</Button></RouterLink>
        <div class="request h">{t('about.title')}</div>
        <div>{t('about.desc')}</div>
        <div class="request h">{t('about.roadmap.title')}</div>
        {this.renderRoadmapSection(t, 'about.roadmap.done', '\u2713 ')}
        {this.renderRoadmapSection(t, 'about.roadmap.progress', '\u270e ')}
        {this.renderRoadmapSection(t, 'about.roadmap.next', '\u231b ')}
        <div>{t('about.roadmap.bookmark')}</div>
        <div class="request h">{t('faq.title')}</div>
        {this.renderQA(this, t)}
        <RouterLink to="/"><Button variant="contained" color="secondary">{t('about.back')}</Button></RouterLink>
      </div>
    );
  }
}

export default withTranslation()(withLoggedOutLayout(AboutFAQView));
