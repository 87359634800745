module.exports={
  "locale": {
    "switch": {
      "de": "auf Deutsch",
      "sv": "på svenska",
      "en": "in English"
    }
  },
  "nav": {
    "findout": "läs mer om det"
  },
  "search": {
    "start": {
      "desc01": "Letar du efter ett uppfyllande arbete med mening?",
      "desc02": "Låt oss göra den tidskrävande sökningen åt dig.",
      "desc03": "Enkelt. Effektivt. Anonymt.",
      "what": { "title": "Vad vill du gärna göra?", "placeholder": "t.ex. sjuksköterska, trädgårdsmästare, tour guide, forskare" },
      "where": { "title": "Var letar du efter det?", "placeholder": "t.ex. Göteborg, Norrland, Island, Berlin" },
      "remote": { "option": "letar (också) efter arbete PÅ DISTANS", "title": "tidzon(er) / geografisk(a) zon(er):", "placeholder": "t.ex. CET, UTC, europa"},
      "action": "Sök"
    }
  },
  "signup": {
    "step2": {
      "headline": "Just nu kan du få följande tjänster just nu. Välja mellan:",
      "option1a": {
        "01": "Woohoo!Vi har precis börjat vår tjänst i {{location}}.",
        "02": "Vi väljer för närvarande ett begränsat antal förstagångskunder som kan använda vår fullständiga tjänst gratis.",
        "03": "Redo för att ta en chans att få tjänsten gratis?",
        "caption": "Ja gärna.",
        "placeholder": "Berätta lite om dig själv och din nuvarande situation. Vad för utmaningar möter du när du letar eftar uppfyllande arbete med mening? Vad är dina omständigheter? (min. 100, max. 1000 tecken)"
      },
      "also": "också ",
      "option1b": "updatera mig {{also}}när tjänsten är tillgänglig i {{location}}.",
      "option2": "håll mig {{also}}informerad och skicka mig alla nyheter om tjänsten.",
    },
    "step3": {
      "headline": "På vilket sätt vill du kommunicera med oss",
      "options": {
        "signal": { "boxtitle": "Signal", "title": "Ditt mobilnummer" },
        "threema": { "boxtitle": "Threema", "title": "Ditt Threema ID" },
        "sources01": "Är du inte med på",
        "sources02": "än? Ingen ko på isen, du kan lada när det på",
        "comingsoon": "Snart, vi kommer att stöda Threema och lite senare också e-post."
      }
    },
    "step4": {
      "headline": "Vi har skickat dig en bekräftelsekod på {{channel}}.",
      "desc01": "Släpp in koden här:"
    },
    "step5": {
      "headline": "Woohoooo!",
      "desc01": "Nu är vi kopplade till varandra.",
      "desc02": "Vi arbetar hårt för att tjäna dig så snart vi kan med något betydande."
    }
  },
  "about": {
    "back": "tillbaka till sökningen ...",
    "title": "Om tjänsten",
    "desc": "somebodyhuman.com är en online tjänst som matchar individer som letar efter nytt arbete och team på organisationer och underlättar effektiva interaktioner. Skaparen är Sarah Rattey.",
    "roadmap": {
      "title": "Kommande milstoppar",
      "bookmark": "... (bokmärkar den här sidan och kom tillbaka för att få nyheter om mer grejer somebodyhuman håller på och utvecklar för dig ;-)",
      "done": [
        "Svensk översättning av tjänsten",
        "Tysk översättning av tjänsten"
      ],
      "progress": [
        "Utveckling av sök bas",
        "Förbattring av sök och match algoritmer"
      ],
      "next": [
        "Tillägnad login och användargränssnitt för utvalda beta-användare för de första regionerna som stöds",
        "Threema integration",
        "Email integration"
      ]
    }
  },
  "faq": {
    "title": "Vanliga frågor",
    "qa": [
      { "q": "Hur hittar jag nytt arbete med somebodyhuman.com?",
        "a": "Kortfattat, när din stad, område, region eller tidszon är täckt, kan du ställa in en profil för det (det tar ungefär 10-15 minuter). När vi hittar något betydande för din sök ska vi meddela dig. Det är upp till dig att bestämma om du vill vidta åtgärder och följa en möjlighet eller inte. Vi använder avancerade, innovativa algoritmer för att matcha dig baserat på vem du är och vem du vill vara i framtiden (istället för vem du var tidigare)."
      },
      { "q": "När kommer min stad, mitt område, min region, mitt land eller mitt tidszon att täckas?",
        "a": "Den enklaste sätt för att ta reda på är att köra en [[sökning|/]]. Vi visar antingen sökresultat om vi redan täcker det här området eller tidszonen eller så erbjuder vi dig att registrera dig för uppdateringar för din region."
      },
      { "q": "Är mina personliga data säkert hos somebodyhuman.com?",
        "a": "Ja, det är dem. Vi ber om så lite data som möjligt för våra tjänster. Angående dina data på din sök profil: Vi använder flera krypteringslager och bara du kan dekryptera dina profildata med ditt lösenord (det händer i din webbläsare). Vi erbjuder lika mycket av tjänsten på ett sätt som gör att du kan vara anonym även mot oss (se även [[gdpr|#hook]]). Du har fullständig kontroll om och hur du kommer att avslöja din identitet (t.ex. mot ett företag som du är intresserad av att arbeta på / för att arbeta med eller mot oss). Vi designar och implementerar våra system med stark tonvikt på anonymitet och datasäkerhet. Var dock medveten om att detta betyder att om du glömmer ditt lösenord kan vi inte återställa ditt konto."
      },
      { "q": "Varför finns det ingen GDPR-bekrätelse grej?", "hook": "gdpr",
        "a": ["Enkelt. Simple. Datasekretess först. Tjänsten är anonymt med flit.",
          "somebodyhuman.com är också avsiktligt transparent med användare.",
          "Detta innebär att när data skickas till våra servrar utlöses det av dig och det är transparent vilken data som skickas och vad de används för.Det är självklart att somebodyhuman.com valde detta uttryckliga samtycke med flit i interaktionsdesignen. Genom att följa denna princip finns det inget behov av att få ett gratiskort som 'acceptera alla cookies' från våra användare. Engligt finns det inget behov av att uppdatera dessa policyer om och om igen, inget behov av att störa våra användare med en ny begäran om att godkänna ändringar på flera sidor policydokument när de ändras.",
          "Om du till exempel kör en sökning skriver du bara in det du letar efter och var. somebodyhuman.com samlar inte in ytterligare data i bakgrunden eller utför andra komprometterande saker i bakgrunden (som många andra företag och webbplatser gör idag). Helt enkelt ger vi dig resultater eller om vi kan inte hittar något som passa ihop med dina kriteria kan du välja mellan alternativer som du kan göra.",
          "Om vi använder (någonlunda) personaliga data för mer än omedelbar tillfredsställelse av en begäran utlöst av dig, du behöver att välja det avsiktligt (vanligtvis en kryssruta eller ett extra knappklick av något slag) för att tillåta oss att göra det. Till exempel, medan du registrerar dig väljer du hur vi kan interagera med dig via din inloggnings-ID (ditt signalnummer, ditt Threema-ID eller din e-postadress).",
          "somebodyhuman.com har ännu frikopplat behandlingen av betalningar på ett sätt så att det är omöjligt att länka en specifik betalning (oavsett om du betalar med kreditkort, PayPal, banköverföring, etc.) med all personlig betalningsinformation till en användarprofil. Det är typ av och det är planerat att publicera en referensimplementering som open sourcei framtiden, så det finns ingen mer ursäkt för någon form av webbplats eller tjänst att fortsätta spåra dig ;-)",
          "Men jag ändrade språkinställningen och du verkar komma ihåg min språkinställning under flera dagar? Detta lagras endast lokalt i din webbläsare på en plats som kallas local storage. Det skickas inte till våra servrar."
        ]
      },
    ]
  },
  "buttons": {
    "continue": "Fortsätt",
    "back": "Tillbaka",
    "reachout": "Nå ut till mig",
    "letsonnect": "Ja, koppla oss!",
    "newsearch": "Nytt Sök",
    "next": "Nästa",
    "submit": "Skicka"
  }
}
