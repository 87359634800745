import { toJson } from './util';

const ALGO_NAME = 'AES-CTR';
const ALGO_LENGTH = 256;
const ALGO_KEY = {name: ALGO_NAME, length: ALGO_LENGTH};
const ALGO_PARAMS = {name: ALGO_NAME, length: 64};
const ALGO_USE = ['encrypt', 'decrypt'];
const PW_KEY_SALT_LENGTH = 64;
const PW_KEY_SALT_ITERATIONS = 24000;
const encoder = new TextEncoder();
const decoder = new TextDecoder();

function getRandomPrintableString(length) {
  let result = [];
  const generatorLength = Math.round(length * 5 / 3);
  while (result.length < length) {
    result = result.concat( Array.prototype.slice.call(crypto.getRandomValues(new Uint8Array(generatorLength))).filter(d => d > 62 && d < 253).map(d => Math.round(d/2) ) );
  }
  result.splice(length, result.length - length);
  return decoder.decode(new Uint8Array(result));
}

function getBufferedUniqueSalt(rawSalt, length) {
  let extendedSalt = rawSalt;

  while (extendedSalt.length < length) {
    extendedSalt += extendedSalt;
  }
  extendedSalt = extendedSalt.substring(0, length);
  const saltBuffer = new ArrayBuffer(length);
  const view = new DataView(saltBuffer);

  for(var i = 0; i < extendedSalt.length; i++) {
    view.setInt8(i, extendedSalt.charCodeAt(i));
  }
  return saltBuffer;
}

function getKeyFromPW(password, salt) {
  return crypto.subtle.importKey(
    "raw",
    encoder.encode(password),
    {name: "PBKDF2"},
    false,
    ["deriveBits", "deriveKey"]
  ).then(function (rawKey) {
    return crypto.subtle.deriveKey(
      {
        "name": "PBKDF2",
        salt: getBufferedUniqueSalt(salt, PW_KEY_SALT_LENGTH),
        "iterations": PW_KEY_SALT_ITERATIONS,
        "hash": "SHA-256"
      },
      rawKey,
      ALGO_KEY, false, ALGO_USE
    );
  });
}


function encryptB(pw, salt, counterPromise, strb) {
  const key = getKeyFromPW(pw, salt);
  return Promise.all([key, counterPromise]).then(function (values) {
    const USABLE_PARAMS = { ...ALGO_PARAMS };
    USABLE_PARAMS.counter = values[1];
    return crypto.subtle.encrypt(USABLE_PARAMS, values[0], strb);
  });
}

function decryptB(pw, salt, counterPromise, strb) {
  const key = getKeyFromPW(pw, salt);
  return Promise.all([key, counterPromise]).then(function (values) {
    const USABLE_PARAMS = { ...ALGO_PARAMS };
    USABLE_PARAMS.counter = values[1];
    return crypto.subtle.decrypt(USABLE_PARAMS, values[0], strb);
  });
}

function encrypt(username, pw, counterPromise, str) {
  return encryptB(pw, username, counterPromise, encoder.encode(str));
  // const key = getKeyFromPW(pw, username);
  // return Promise.all([key, counterPromise]).then(function (values) {
  //   const USABLE_PARAMS = { ...ALGO_PARAMS };
  //   console.log('enc values:');
  //   console.log(values);
  //   USABLE_PARAMS.counter = values[1];
  //   return crypto.subtle.encrypt(USABLE_PARAMS, values[0], encoder.encode(str));
  // });
}

function encryptVault(username, pw, obj) {
  const counter = getRandomCounter();
  const content = encrypt(pw, username, counter, toJson(obj));
  return { counter: Array.prototype.slice.call(counter), content: Array.prototype.slice.call(content) };
}

function decrypt(username, pw, counterPromise, strb) {
  return decryptB(pw, username, counterPromise, strb).then(function (decbytes) {
      return decoder.decode(decbytes);
    });
  // const key = getKeyFromPW(pw, username);
  // return Promise.all([key, counterPromise]).then(function (values) {
  //   const USABLE_PARAMS = { ...ALGO_PARAMS };
  //   USABLE_PARAMS.counter = values[1];
  //   return crypto.subtle.decrypt(USABLE_PARAMS, values[0], strb).then(function (decbytes) {
  //     return decoder.decode(decbytes);
  //   });
  // });
}

function getRandomCounter() {
  return crypto.getRandomValues(new Uint8Array(16));
}

// function pdkf2_sha512(pw) {
//   return crypto.pbkdf2Sync(pw, getRandomPrintableString(24), PW_KEY_SALT_ITERATIONS, 64, 'sha512');
// }


module.exports.encrypt = encrypt;
module.exports.decrypt = decrypt;
module.exports.getRandomCounter = getRandomCounter;
module.exports.getRandomPrintableString = getRandomPrintableString;
// module.exports.pdkf2_sha512 = pdkf2_sha512;

module.exports.encryptVault = encryptVault;

// in browser testing:
//
// single encryption layer
//
// var counter = crypto.getRandomValues(new Uint8Array(16));
// var un = 'userx';
// var pw = 'passwd';
// var str = 'Sekret';
//
// var strenc = encrypt(un, pw, counter, str);
// decrypted = decrypt(un, pw, counter, strenc);

// double independent encryption layers (this only works with symmetric non-block algorithms like aes-ctr)
//
// const str = 'sekret';
// const strb = new TextEncoder().encode(str);
// const pws = 'pwServer';
// const pwc = 'pClient';
// const salts = 'saltis';
// const saltc = 'saltclient';
// const counterc = getRandomCounter();
// const counters = getRandomCounter();
//
// cticket = await encryptB(pwc, saltc, counterc, strb);
// csticket = await encryptB(pws, salts, counters, cticket);
// sticket = await decryptB(pwc, saltc, counterc, csticket);
// ticket = await decryptB(pws, salts, counters, sticket);
//
// new TextDecoder().decode(ticket);
