import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from '../actions/index';

import { api } from '../api';

import * as Req from './request';

function throwNon200Resp(resp) {
  if (resp.status != 200) {
    if (resp.status > 499 && resp.status < 600) {
      throw 'Whooopsie, something went wrong on our side. We have been informed about the problem. Try again or come back later.';
    } else {
      throw 'Whooopsie, something went wrong inside your browser. Check your input and try again.';
    }
  }
  return null;
}

function* searchRequest(action) {
  try {
    const resp = yield call(fetch, api['search'], Req.post(action.payload) );
    throwNon200Resp(resp);

    const resp_json = yield resp.json();
    if (resp_json != null) {
      console.log('ouoeuoeuoeuoeuoeu');
      yield put(Actions.direct.search.setResult.action(resp_json));
    }
  } catch (e) {
    const msg = e.message != null ? e.message : e;
    console.log(msg);
    yield put(Actions.direct.snackbar.enqueue.action({
      message: msg.startsWith('Whooopsie') ? msg : 'Something went wrong. Could not run search. Check, if your internet connection works.',
      options: { variant: 'error' }
    }));
  }

}

function* searchSaga() {
  yield takeLatest(Actions.complex.search.request.id, searchRequest);
}

export const sagas = [ searchSaga ];
