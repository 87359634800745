import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { Link } from "@reach/router";

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { username: '', password: '' };
  }

  stateChange(field, e) {
    const nState = {};
    nState[field] = e.target.value;
    this.setState(nState);
  }

  render() {
    const buttonDisabled = this.state.username == null || this.state.username.trim().length < 1 ||
      this.state.password == null || this.state.password.trim().length < 3;

    const messages = !this.props.app_messages ? null : this.props.app_messages.map (function (item){
        return <span className={item.type}>{item.msg}</span>
      }, this);

    return (
      <Container maxWidth="xs" id="login-form">
        <img src={resources_json['logo.svg']} />
        <div>{messages}</div>
        <TextField
          id="standard-name"
          label="Username"
          className=""
          margin="normal"
          value={this.state.username}
          onChange={this.stateChange.bind(this,'username')}
        />
        <TextField
          id="standard-password-input"
          label="Password"
          className=""
          type="password"
          // autoComplete="current-password"
          margin="normal"
          value={this.state.password}
          onChange={this.stateChange.bind(this,'password')}
        />
        <Button onClick={this.loginButtonClick.bind(this)} variant="contained" color="primary" disabled={buttonDisabled}>Login</Button>
        <p>or</p>
        <Link to="/"><Button variant="contained" color="secondary">Sign up for updates</Button></Link>
      </Container>
    );
  }

  loginButtonClick() {
    this.props.login(this.state.username, this.state.password);
  }
}
