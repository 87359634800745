import i18n from 'i18next';
// import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translation_de from '../locales/de/translation.json';
import translation_en from '../locales/en/translation.json';
import translation_sv from '../locales/sv/translation.json';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'sv', 'de'];

i18n
  // TODO find a solution to load translation files with hashes that prevents any caching of changes in translation updates
  // .use(HttpApi) // load translation using xhr -> see /public/locales. We will add locales in the next step

  .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    fallbackLng : fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    // backend: { loadPath: '/public/locales/{{lng}}/{{ns}}.json' }, see TODO for HttpApi
    resources: {
      en: { translation: translation_en },
      de: { translation: translation_de },
      sv: { translation: translation_sv },
    },
    debug: true,
    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    },
  });

export default i18n;
