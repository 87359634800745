import React from 'react';
import Button from '@material-ui/core/Button';

export default class MainView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="app-main">
        <div>Hej Hej ...</div>
        <Button onClick={this.logoutButtonClick.bind(this)} variant="outlined" color="primary">Logout</Button>
      </div>
    );
  }

  logoutButtonClick() {
    this.props.logout();
  }
}
