import * as Actions from '../actions/index';
import Pure from 'pure-aid';

function loginSuccess(state, payload) {
  state.username = payload.username;
  state.pin = payload.pin;
  state.password = payload.password;
  state.vault = payload.vault;
  // state.app_messages = [ { type: 'success', msg: 'Welcome back.' }];
  return state;
}

function loginFail(state, payload) {
  // TODO impl
  // state.app_messages = [ { type: 'error', msg: 'Login failed.' }];
  return state;
}

function logout(state) {
  state.username = null;
  state.pin = null;
  state.password = null;
  // state.app_messages = [ { type: 'success', msg: 'You have successfully logged out.' }];
  return state;
}

const actions = {};

actions[Actions.direct.session.loginSuccess.id] = loginSuccess;
actions[Actions.direct.session.loginFail.id] = loginFail;
actions[Actions.direct.session.logout.id] = logout;

export { actions };
