import React from 'react';
import Container from '@material-ui/core/Container';

import Notifier from '../notifier';
import Login from '../login';
import Main from '../main';

export default class SessionContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isLoggedOut = this.props.isLoggedOut;
    return (
        <Container maxWidth="xs" id="session-container">
        <Notifier />
        { isLoggedOut ? (
          <div>
            <Login />
          </div>
        ) : (
          <div>
            <Main />
          </div>
        ) }
        </Container>
    );
  }
}
