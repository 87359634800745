module.exports={
  "locale": {
    "switch": {
      "de": "auf Deutsch",
      "sv": "på svenska",
      "en": "in English"
    }
  },
  "nav": {
    "findout": "find out more"
  },
  "search": {
    "start": {
      "desc01": "Looking for satisfying, fulfilling work with purpose?",
      "desc02": "Let us do the time-consuming search for you.",
      "desc03": "Easy. Effective. Anonymous.",
      "what": { "title": "What would you love to do?", "placeholder": "e.g. Nurse, Gardener, Tour Guide, Researcher" },
      "where": { "title": "Where are you looking?", "placeholder": "e.g. Göteborg, Norrland, Iceland, Berlin" },
      "remote": { "option": "(also) search for REMOTE work", "title": "Time zone(s) / geographic zone(s):", "placeholder": "e.g. CET, UTC, Europe"},
      "action": "Search"
    }
  },
  "signup": {
    "step2": {
      "headline": "Right now, you can get one or more of the following services. Make your choice:",
      "option1a": {
        "01": "Woohoo! We have just started our service in {{location}}.",
        "02": "We are hand-picking a limited number of first customers right now, who can enjoy our full service free of charge.",
        "03": "Wanna be considered for a free pass?",
        "caption": "Yes, I would love to.",
        "placeholder": "Tell us a bit about you and your current situation. What are the challenges for you to find meaningful, satisfying work? What are your circumstances? (min. 100, max. 1000 characters)"
      },
      "also": "also ",
      "option1b": "{{also}}update me once the service is available in {{location}}",
      "option2": "{{also}}keep me in the loop and send me all updates about the service.",
    },
    "step3": {
      "headline": "How do you want us to communicate?",
      "options": {
        "signal": { "boxtitle": "Signal", "title": "Your phone number" },
        "threema": { "boxtitle": "Threema", "title": "Your Threema ID" },
        "sources01": "You do not have",
        "sources02": "yet? No worries, you can get it at",
        "comingsoon": "We will soon support Threema and a bit later also email."
      }
    },
    "step4": {
      "headline": "We have sent a request to you on {{channel}}.",
      "desc01": "Please provide the code we have sent you:"
    },
    "step5": {
      "headline": "Woohoooo!",
      "desc01": "We are now connected with each other.",
      "desc02": "We are working hard to serve you as soon as we can with something substantial."
    }
  },
  "about": {
    "back": "get back to the search ...",
    "title": "About the service",
    "desc": "somebodyhuman.com is an online service facilitating effective matching of and interactions between individuals looking for work and teams in organisations. It's creator is Sarah Rattey.",
    "roadmap": {
      "title": "Roadmap",
      "bookmark": "... (bookmark this site and come back to stay tuned about more stuff somebodyhuman is building for you ;-)",
      "done": [
        "Service available in Swedish / svensk översättning",
        "Service available in German / deutsche Übersetzung"
      ],
      "progress": [
        "Building up the search base",
        "Improving search and matching algorithms"
      ],
      "next": [
        "Dedicated login and UI for hand-picked beta users for the first supported regions",
        "Threema integration",
        "Email integration"
      ]
    }
  },
  "faq": {
    "title": "Frequently asked questions",
    "qa": [
      { "q": "How do I find work using somebodyhuman.com?",
        "a": "In short, once your city, area, region or timezone is covered, you can setup a search profile for it (it will take 10-15 minutes). We then only notify you once we have found a some suitable work according to your search. It is up to you to decide if you want to take action and pursue an opportunity or not. We are using advanced algorithmic innovations to match you based on who you are and who you want to be in the future (rather than based on who you were in the past)."
      },
      { "q": "When will my city, area, region, country or timezone be available?",
        "a": "The easiest way to find out is to run a [[search|/]]. We show you either search results if we already cover this area or timezone or we offer you to sign up for updates for your region, so you will be notified once we can serve you."
      },
      { "q": "Are my personal data safe with you?",
        "a": "Yes, they are. We ask for as few personal data as possible to provide our servie for you. For the data of your personal search profile: We use several layers of encryption and only you can decrypt your profile data with your password (just inside your browser). We provide as much of the service in a way that allows you to stay anonymous even towards us (see also [[gdpr|#hook]]). You are in complete control, if and how you will reveal your identity (e.g. towards a company you are interested in working at/ in working with or towards us). We deliberately design and implement our systems with a strong emphasis on anonymity and data security. Be aware though, that this means, if you forget your password, we cannot restore your account."
      },
      { "q": "Why is there no GDPR consent thingy?", "hook": "gdpr",
        "a": ["Simple. Privacy first. The service is anonymous by design.",
          "somebodyhuman.com is also transparent towards their users by design.",
          "This means that whenever data is send to our servers, it is triggered by you and it is transparent which data will get sent and what they are used for. somebodyhuman.com opted for this explicit consent by design in our interaction design because it's a no brainer. By following this principle there is no need to get a free pass like 'accept all cookies' from our users, no need to update those policies again and again, no need to bother our users with a new request to approve changes in several pages long policy documents whenever they change.",
          "If, for example, you run a search, then you just type in what you are looking for and where. somebodyhuman.com does not collect any additional data in the background or performs other privacy or security compromising things in the background (like many other companies and websites do nowadays). We simply provide you with the results or alternative options you can consider, if we cannot provide you with a quality search result matching you criteria.",
          "If we use (somewhat) personal data, for more than the immediate satisfaction of a request triggerd by you, you need to opt-in within this specific (usually a checkbox or an extra button click of some sort) to allow us to do that. For example, while you sign up, you pick how we can interact with you through login ID (be it your Signal number, your Threema ID or your Email address).",
          "somebodyhuman.com has even decoupled the processing of payments in a way that it is impossible to link a specific payment (regardless if you pay with credit card, paypal, bank transfer, etc.) with all the personal payment data to a user profile. Sounds like magic? It kinda is and it is planned to release a reference implementation as open source in the future, so there is no more excuse for any kind of website or service to keep tracking you ;-)",
          "But, I changed the language setting and you seem to remember my language setting over days? This is only stored locally in your browser in a place called local storage. It is not sent to our servers."
        ]
      },
    ]
  },
  "buttons": {
    "continue": "Continue",
    "back": "Go back",
    "reachout": "Reach out to me",
    "letsonnect": "Yes, let's connect!",
    "newsearch": "New Search",
    "next": "Next",
    "submit": "Submit"
  }
}
