import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import sagas from './sagas/index';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

import rootReducer from './reducers/index';

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// start all the sagas
sagas.forEach(saga => sagaMiddleware.run(saga));

export default store;
