import { connect } from 'react-redux';

import LoginForm from './display/login-form';
import { api } from '../api';
import * as Actions from '../actions/index';

const mapStateToProps = state => {
  return {
    text: state.present.text,
    username: state.present.username,
    password: state.present.password,
    app_messages: state.present.app_messages
  }
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      dispatch(Actions.complex.session.login.action({ username: username, password: password }) );
    }
  }
};

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export default Login;
