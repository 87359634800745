import { connect } from 'react-redux';

import SessionContainer from './display/session-container';
import { api } from '../api';
import * as Actions from '../actions/index';

const mapStateToProps = state => {
  return {
    isLoggedOut: (state.present.username == null || state.present.username.length < 1 ? true : false),
    app_messages: state.present.app_messages
  }
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(Actions.direct.session.logout.action())
  }
};

const Session = connect(mapStateToProps, mapDispatchToProps)(SessionContainer);

export default Session;
