import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export default class SelectiveList extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.selected != null) {
      this.state = { };
    } else {
      this.state = {
        selected: this.props.mode == 'multi' ? new Set() : -1
      };
    }
  }

  getSelected() {
    return (this.props.selected != null) ? this.props.selected : this.state.selected;
  }

  select(i) {
    let newSelection = this.getSelected();
    if (this.props.mode == 'multi') {
      let newSelection = new Set(this.getSelected());
      // enforce toggle
      if (newSelection.has(i)) {
        newSelection.delete(i);
      } else {
        newSelection.add(i);
      }
    } else {
      // this toggles/deactivates the previous selection automatically
      newSelection = i;
    }
    // change the selection
    if (this.props.selected == null) {
      this.setState({ selected: newSelection });
    } else {
      this.props.changeSelection(newSelection);
    }

    // if there is a callback configured, send the newSelection to it
    if (this.props.selectionChanged != null) {
      this.props.selectionChanged(newSelection);
    }
  }

  isSelected(i) {
    if (this.getSelected() != null) {
      return typeof this.getSelected() === 'number' ?
        this.getSelected() === i : this.getSelected().has(i);
    }
    return false;
  }

  // expects an array of selectables=[{name: 'selectable a', id: 'a' },...]
  // and optionally a map of renderable sections, key must match id property of
  // selectable selectableSections={ 'a': <Field .../>, 'b': [<span>ABC</span>, ...]}
  //
  // if you want to store the selection longer
  // (e.g. if this list component gets rerendered or recreated after outside actions)
  // you can use set selected={this.props.[nameForOuterProp]} changeSelection={this.[parentComponentMethod]}
  render() {
    const items = this.props.selectables.map( (p, i) => {
      const inputs = (this.props.selectableSections[p.id] != null) ?
        <div className={'items' + (this.isSelected(i) ? '' : ' hidden')}>{this.props.selectableSections[p.id]}</div>
        : null;
      return (
        <ListItem key={'selectable-' + i} dense disableRipple button onClick={this.select.bind(this, i)}>
          <div className={'title' + (this.isSelected(i) ? ' selected' : '')}>{p.name}</div>
          {inputs}
        </ListItem>
      )
    });

    return (
      <List component="nav" aria-label="main mailbox folders" className="selective-list" id={'selective-list-'+this.props.id}>
        {items}
      </List>
    );
  }
}
