import { connect } from 'react-redux';

import UpdatesSignUpForm from './display/updates-signup-form';
import { api } from '../api';
import * as Actions from '../actions/index';

const mapStateToProps = state => {
  return {
    coveredBeta: state.present.coveredBeta,
    notYetCovered: state.present.notYetCovered,
    selectedChannel: state.present.selectedChannel,
    step: state.present.updatesSignupStep,
    waitForOutsideStepIncrement: new Set([1, 3, 4])
  }
};

const mapDispatchToProps = dispatch => {
  return {
    continueStepRequest: (step, data) => {
      switch (step) {
        case 1:
          dispatch(Actions.complex.search.request.action(data));
          break;
        case 2:
            dispatch(Actions.direct.search.updates.setSelection.action(data));
            break;
        case 3:
          dispatch(Actions.complex.signup.codeRequest.action(data));
          break;
        case 4:
          dispatch(Actions.complex.signup.request.action(data));
          break;
      }
    },
    channelSelectionChanged: (newSelection) => {
      dispatch(Actions.direct.search.updates.setChannel.action({ selectedChannel: newSelection }));
    },
    setStep: (i) => {
      dispatch(Actions.direct.search.updates.setSignupStep.action({ step: i }));
    }
    // submit: (data) => {
    //   // not used, but kept here for later reference as a comment
    // }
  }
};

const UpdatesSignUp = connect(mapStateToProps, mapDispatchToProps)(UpdatesSignUpForm);

export default UpdatesSignUp;
