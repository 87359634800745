import * as Actions from '../actions/index';

function enqueue(state, payload) {
  const notifications = payload.notification.clear == true ? [] : state.notifications;
  return {
      ...state,
      notifications: [
          ...notifications,
          {
              key: payload.key,
              ...payload.notification
          }
      ]
  };
}

// function close(state, payload) {
//   const return_state = {
//       ...state,
//       notifications: state.notifications.map(notification => (
//           (payload.dismissAll || notification.key === payload.key)
//               ? { ...notification, dismissed: true }
//               : { ...notification }
//       )),
//   };
//
//   return return_state;
// }
//
// function closeAll(state, payload) {
//   return {
//       ...state,
//       notifications: []
//   };
//
//   return return_state;
// }
//
// function remove(state, payload) {
//   return {
//       ...state,
//       notifications: state.notifications.filter(
//           notification => notification.key !== payload.key,
//       ),
//   };
// }

const actions = {};

actions[Actions.direct.snackbar.enqueue.id] = enqueue;
// actions[Actions.CLOSE_SNACKBAR] = close;
// actions[Actions.CLOSE_ALL_SNACKBARS] = closeAll;
// actions[Actions.REMOVE_SNACKBAR] = remove;

export { actions };
//
// const defaultState = {
//     notifications: [],
// };
//
// export default (state = defaultState, action) => {
//     switch (action.type) {
//         case ENQUEUE_SNACKBAR:
//
//
//         case CLOSE_SNACKBAR:
//             return {
//                 ...state,
//                 notifications: state.notifications.map(notification => (
//                     (action.dismissAll || notification.key === action.key)
//                         ? { ...notification, dismissed: true }
//                         : { ...notification }
//                 )),
//             }
//
//         case REMOVE_SNACKBAR:
//             return {
//                 ...state,
//                 notifications: state.notifications.filter(
//                     notification => notification.key !== action.key,
//                 ),
//             };
//
//         default:
//             return state;
//     }
// };
