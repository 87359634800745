
function getCallableAction(type, validations, preparations) {
  if (validations != null) {
    return function (payload) {
      return { type: type, payload: preparations(payload), valid: validations(payload) };
    }
  }
  return (payload) => ({ type: type, payload: preparations(payload) })
}

function getIdentifiableAction(type, validations, preparations) {
  // preparations get called after validations, but before the action is called
  // preparations allow to use simpler payloads to be used within the code
  // ... without having trouble of satisfying naming or formatting conventions
  // of plugins providing specific actions (e.g. snackbars)

  // if no payload preparations are defined (default), simply return shallow copy of payload to adhere to immutability conventions
  // TODO consider deep copy for undoable plugin or should that be handled inside the reducers?
  const payloadPreparations = preparations != null ? preparations : function (payload) { return { ...payload }; };
  return { id: type, action: getCallableAction(type, validations, payloadPreparations) };
}

// TODO differentiate between Saga and Reducer Actions
const directPrep = {
  snackbar: {
    enqueue: (payload) => {
        const key = payload.options && payload.options.key;
        return { notification: { ...payload, key: key || new Date().getTime() + Math.random() } };
    }
  }
};

// direct contains actions that trigger reducers
export const direct = {
  search: {
    setResult:        getIdentifiableAction('direct.search.setResult'),
    updates: {
      setSignupStep:  getIdentifiableAction('direct.search.updates.setSignupStep'),
      setSelection:   getIdentifiableAction('direct.search.updates.setSelection'),
      setChannel:     getIdentifiableAction('direct.search.updates.setChannel'),
    }
  },
  session: {
    loginSuccess:     getIdentifiableAction('direct.session.loginSuccess'),
    loginFail:        getIdentifiableAction('direct.session.loginFail'),
    logout:           getIdentifiableAction('direct.session.logout'),
  },
  snackbar: {
    // enqueuePrep:      (payload) => {
    //     const key = payload.options && payload.options.key;
    //     return { notification: { ...payload, key: key || new Date().getTime() + Math.random() } };
    //   },
    enqueue: getIdentifiableAction('direct.snackbar.enqueue', null, directPrep.snackbar.enqueue),
    // export const closeSnackbar = (key) => ({ type: CLOSE_SNACKBAR, payload: { dismissAll: !key, key } }); // dismiss all if no key has been defined
    // export const closeAllSnackbars = () => ({ type: CLOSE_ALL_SNACKBARS, payload: { } }); // remove without dismissing
    // export const removeSnackbar = (key) => ({ type: REMOVE_SNACKBAR, payload: { key } });
  }

};

// complex contains actions that trigger sagas

export const complex = {
  search: {
    request:        getIdentifiableAction('complex.search.request')
  },
  signup: {
    search:         getIdentifiableAction('complex.signup.search'),
    request:        getIdentifiableAction('complex.signup.request'),
    codeRequest:    getIdentifiableAction('complex.signup.codeRequest'),
  },
  session: {
    login:          getIdentifiableAction('complex.session.login'),
  }
};
