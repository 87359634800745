import React from 'react';
import Container from '@material-ui/core/Container';
import Notifier from '../notifier';
import LocaleSwitch from './locale-switch';

const withLoggedOutLayout = (Component) => (props) => {
    return (
      <Container maxWidth="sm" id="signup-form">
        <img src={resources_json['logo.svg']} height={80} key="usignup-logo" />
        <LocaleSwitch />
        <Notifier />
        <Component {...props} />
      </Container>
    );
}

export default withLoggedOutLayout;
