function validateString(str, minLength = 1) {
  return str != null && str.trim().length >= minLength;
}

function validateArray(arr, minLength = 1, validateItem = undefined) {
  return arr != null && arr.length >= minLength && (validateItem == undefined || arr.findIndex(x => validateItem(x) != true) < 0);
}

function validateMap(map, keys, validateValue = validateString) {
  const copy = {... map};
  const firstInvalidIndex = keys.findIndex(k => {
    delete copy[k];
    return validateValue(map[k]) != true;
  });

  return firstInvalidIndex < 0 && Object.keys(copy).length <= 0;
}

function toJson(obj) {
  return JSON.stringify(obj);
}

function toStr(json) {
  return JSON.parse(json);
}

module.exports.validateString = validateString;
module.exports.validateArray = validateArray;
module.exports.validateMap = validateMap;
module.exports.toJson = toJson ;
module.exports.toStr = toStr ;

// // test validate map
// m = { a: 'b', c: 'd' }
// m2 = { a: 'b', c: 'd', e: '   ' }
// m3 = { a: 'b', c: 'd', e: undefined }
// m4 = { a: 'b', c: 'd', e: null }
// keys = ['a']
// keys2 = ['a', 'c']
//
// // yields true
// validateMap(m, keys2)
//
// // all other yield false
// validateMap(m, keys)
// validateMap(m2, keys2)
// validateMap(m3, keys2)
// validateMap(m4, keys2)
