module.exports={
  "locale": {
    "switch": {
      "de": "auf Deutsch",
      "sv": "på svenska",
      "en": "in English"
    }
  },
  "nav": {
    "findout": "mehr erfahren"
  },
  "search": {
    "start": {
      "desc01": "Suchst du nach einer Job und einer Karriere mit Sinn?",
      "desc02": "Überlass' uns die zeitraubende Suche.",
      "desc03": "Einfach. Effektiv. Anonym.",
      "what": { "title": "Was würdest du gern machen?", "placeholder": "z.B. Krankenpfleger, Gärtner, Tour Guide, Forscher" },
      "where": { "title": "Wo suchst du?", "placeholder": "e.g. Berlin, Hamburg, Göteborg, Island" },
      "remote": { "option": "(auch) nach REMOTE Jobs suchen", "title": "Zeitzone(n) / Geographische Zone(n):", "placeholder": "z.B. CET, UTC, Europa"},
      "action": "Suche starten"
    }
  },
  "signup": {
    "step2": {
      "headline": "Momentan können wir folgendes für dich tun. Wähle aus, welche Services du in Anspruch nehmen möchtest:",
      "option1a": {
        "01": "Woohoo! Seit kurzem gibt es unseren Service auch in {{location}}.",
        "02": "Aktuell wählen wir unsere ersten Kunden aus, die unseren Service kostenfrei in Anspruch nehmen können.",
        "03": "Bereit für eine Chance auf einen Freifahrtsschein?",
        "caption": "ja, den hätte ich gern.",
        "placeholder": "Beschreibe deine aktuelle Situation für uns. Was für Herausforderungen stehen dir aktuell bei der Suche nach erfüllender Arbeit mit Sinn gegenüber? Wie sehen deine Umstände aus? (min. 100, max. 1000 Zeichen)"
      },
      "also": "auch ",
      "option1b": "gebt mir {{also}}Bescheid, sobald der Service in {{location}} verfügbar ist.",
      "option2": "haltet mich {{also}}über andere somebodyhuman.com Neuigkeiten auf dem Laufenden.",
    },
    "step3": {
      "headline": "Wie willst du mit uns in Kontakt bleiben?",
      "options": {
        "signal": { "boxtitle": "Signal", "title": "Deine Telefonnummer" },
        "threema": { "boxtitle": "Threema", "title": "Deine Threema ID" },
        "sources01": "Du hast noch kein",
        "sources02": "? Kein Problem. Hier geht's zum Download:",
        "comingsoon": "Bald unterstützen wir auch Threema und etwas später dann auch E-Mail."
      }
    },
    "step4": {
      "headline": "Wir haben dir eine Nachricht via {{channel}} geschickt.",
      "desc01": "Gib' zur Bestätigung hier den Code ein:"
    },
    "step5": {
      "headline": "Woohoooo!",
      "desc01": "Jetzt sind wir connected.",
      "desc02": "Wir arbeiten daran, dir so schnell wie möglich etwas Handfestes zu liefern."
    }
  },
  "about": {
    "back": "zurück zur Suche ...",
    "title": "Über den Service",
    "desc": "somebodyhuman.com ist ein Onlinedienst, der arbeitssuchende Personen und Teams in Organisationen effektiv zusammenbringt. Der Dienst wurde von Sarah Rattey geschaffen.",
    "roadmap": {
      "title": "Nächste Meilensteine",
      "bookmark": "... (setz' dir ein Lesezeichen und schau' später wieder hier vorbei, um auf dem laufenden zu bleiben, was somebodyhuman gerade für dich umsetzt ;-)",
      "done": [
        "Service auf Schwedisch verfügbar machen",
        "Service auf Deutsch verfügbar machen"
      ],
      "progress": [
        "Die Suchdatenbank aufbauen",
        "Den Suchalgorithmus verbessern"
      ],
      "next": [
        "Dedizierter Login und dedizierter Bereich für handverlesene Beta-Anwender für die ersten, unterstützten Regionen",
        "Threema Integration",
        "Email Integration"
      ]
    }
  },
  "faq": {
    "title": "Häufig gestellte Fragen",
    "qa": [
      { "q": "Wie finde ich mit somebodyhuman.com neue Arbeit?",
        "a": "Sobald deine Stadt, dein Suchgebiet, deine Region oder Zeitzone abgedeckt ist, kannst du ein Suchprofil dafür anlegen (du brauchst ca. 10-15 Minuten dafür). Wir melden uns dann bei dir, sobald wir etwas Passendes für dich gefunden haben. Dann ist es an dir zu entscheiden, ob du die Gelegenheit nutzen und mit deinem Match in Kontakt treten willst. Wir verwenden moderne, innovative Algorithmen, um dich anhand dessen, wer du bist und wer du in Zukunft sein willst (statt wer du in der Vergangenheit warst) auf deine ganz persönlichen Nadeln im Heuhaufen aufmerksam zu machen."
      },
      { "q": "Ab wann wird meine Stadt, mein Suchgebiet, meine Region, mein Land oder meine Zeitzone abgedeckt?",
        "a": "Der einfachste Weg das herauszufinden: Für einfach eine [[Suche|/]] aus. Du bekommst dann entweder direkt Suchergebnisse angezeigt, wenn wir ein Gebiet oder eine Zeitzone bereits abdecken, oder wir bieten dir an, dich zu benachrichtigen, sobald wir Etwas handfestes für dich in deinem Suchgebiet haben."
      },
      { "q": "Sind meine persönlichen Daten sicher?",
        "a": "Ja. Wir fragen nach so wenig wie möglich personenbezogenen Daten, um unseren Service für dich zur Verfügung zu stellen. Bezüglich deiner Daten in deinem Suchprofil: Wir verwenden mehrere Verschlüsselungsschichten und nur du kannst dein Profil mit deinem Passwort entschlüsseln (das passiert direkt auf deinem Rechner in deinem Browser). Wir stellen den Service so zur Verfügung, dass du uns gegenüber anonym bleiben kannst. (mehr dazu auch unter der Frage: [[gdpr|#hook]]). Du hast die koplette Kontrolle, ob und wie du deine Identität preisgeben willst (z.B. einem Unternehmen gegenüber, an dem du interessiert bist oder uns gegenüber). Der Dienst ist gezielt unter den Aspekten der Gewährleistung von Anonymität und Datensicherheit konzipiert und entwickelt. Das bedeutet allerdings auch: Wenn du dein Passwort vergisst können wir deinen Account nicht durch ein Zurücksetzen deines Passwortes o.ä. wiederherstellen."
      },
      { "q": "Warum poppt nirgends ein GDPR Zustimmungsdingsda auf?", "hook": "gdpr",
        "a": ["Ganz einfach. Privatsphäre hat oberste Priorität. Der Service ist von Hause aus anonym nutzbar.",
          "somebodyhuman.com ist von Hause aus auch transparent gegenüber seinen Nutzern.",
          "Das heißt, dass jeder Versand von Daten zu unseren Servern durch eine Aktion von dir ausgelöst wird und bei jeder dieser Aktionen für dich klar ersichtlich ist, welche Daten gesendet werden und wofür sie benutzt werden. somebodyhuman.com hat sich für explizite Zustimmung im Interacktionsdesign entschieden, weil das transparent und geradlinig ist. Durch dieses Grundprinzip braucht somebodyhuman.com keinen Freifahrtsschein à la 'Alle Cookies akzeptieren' und es erspart ständig die Nutzungsbedingungen anpassen zu müssen. Dir, dem Nutzer, erspart ständig neue Aktualiserungen verstehen und ihnen zustimmen zu müssen.",
          "Wenn du beispielsveise eine Suche ausführen willst, dann gibst du einfach deine Suchkriterien ein und das wars. somebodyhuman.com sammelt keinerlei, zusätzliche Daten im Hintergrund oder macht sonst irgenwelche, privatsphäre- oder sicherheitskompromitterenden Sachen im Hintergrund (was viele andere Unternehmen und Webseiten heutzutage machen). Wir liefern dir einfach die Ergebnisse oder Handlungsalternativen, die du in Erwägung ziehen kannst, wenn wir nichts Passendes entsprechend deiner Suchkriterien für dich finden können.",
          "An den Stellen, an denen wir (teilweise) persönliche Daten über die unmittelbare Aktion hinaus verwenden, musst du stets explizit zustimmen (meist ist das ein Häkchen oder ein extra Button, den du anklicken musst). Zum Beispiel wählst du während des Registrierungsvorganges wie wir mit dir über deine login ID interagieren können (je nachdem ob es deine Signal number, deine Threema ID oder deine E-Mail Adresse ist).",
          "somebodyhuman.com hat sogar den Bezahlprozess soweit vom Rest der Plattform entkoppelt, dass es unmöglich ist einen bestimmten Bezahlvorgang (mit bestimmten Bezahldaten) unabhängig von einer bestimmten Zahlungsweise (Kreditkarte, Paypal, Überweisung, etc.) einem Profil zuzuordnen. Klingt nach Magie? Ist es auch irgendwie und wir planen eine Referenzimplementierung davon als Open Source zu veröffentlichen, sodass es in Zukunft keine Ausreden für egal welche Art von Webseite oder Onlineplattform dich weiterhin zu tracken ;-)",
          "Aber ich habe doch die Anzeigesprache geändert und der Service scheint sich auch nach Tagen noch daran zu erinnern, welche Sprache ich gewählt habe? Diese Einstellung wird nur lokal (auf deinem Gerät) in deinem Browser (in dessem sogenannten Local Storage) gespeichert. Es wird nicht an unsere Server geschickt."
        ]
      },
    ]
  },
  "buttons": {
    "continue": "Weiter",
    "back": "Zurück",
    "reachout": "Mir eine Anfrage schicken",
    "letsonnect": "Ja, lass' uns connecten!",
    "newsearch": "Neue Suche",
    "next": "Weiter",
    "submit": "Absenden"
  }
}
