export const get = () => ({
  method: 'GET',
  headers: { 'X-CSRFToken': csrfToken, 'Content-Type': 'application/json' }
});

export const post = (data) => ({
  method: 'POST',
  headers: { 'X-CSRFToken': csrfToken, 'Content-Type': 'application/json' },
  body: JSON.stringify(data)
});

export const put = (data) => ({
  method: 'PUT',
  headers: { 'X-CSRFToken': csrfToken, 'Content-Type': 'application/json' },
  body: JSON.stringify(data)
});
