import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputAdornment from '@material-ui/core/InputAdornment';
import Phone from '@material-ui/icons/Phone';
import Search from '@material-ui/icons/Search';
import Satellite from '@material-ui/icons/Satellite';
import AccessTime from '@material-ui/icons/AccessTime';

import { Link as RouterLink } from "@reach/router";

import SelectiveList from './selective-list';
import withLoggedOutLayout from './layout-loggedout';


function isEmpty(primitive) {
  if (typeof primitive == 'number' || typeof primitive == 'boolean') {
    return false;
  }
  return primitive == null || primitive.trim().length < 1;
}

const preventDefault = (event) => event.preventDefault();

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  }
};

const classNames = [ 'highlight', 'done'];
const invertedClassNames = [...classNames].reverse();

function getCssClass(str, minLength, compare, invertCompare) {
  const trimmed = str ? str.trim() : '';
  if (trimmed.length <= 0) {
    return '';
  }
  if (trimmed.length < minLength) {
    return classNames[0];
  } else {
    const result = invertCompare === true ? invertedClassNames : classNames;
    return (compare != null ? (trimmed === compare.trim() ? result[1] : result[0]) : 'done');
  }
}

class UpdatesSignUpForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      step: 1, messages: '', maxSteps: 5,
      what: '', where: '', timeZones: '',
      channel: '', channels: [
        { name: 'Signal', id: 'signal' },
        // { name: 'Threema', id: 'threema' }
      ],
      signalId: '', threemaId: '',
      confirmationCode: '',
    };
  }

  stateChange(field, e) {
    const nState = {};
    nState[field] = e.target.value;
    this.setState(nState);
  }

  stateToggle(field, e) {
    const nState = {};
    nState[field] = this.state[field] == true ? false : true;
    if (field == 'updateFull' && nState[field] == true
      && this.props.notYetCovered != null && this.props.notYetCovered.length > 0) {
      nState['updateForSelection'] = true;
    }
    this.setState(nState);
  }

  getStep() {
    return (this.props.step != null) ? this.props.step : this.state.step;
  }

  setStep(i) {
    if (this.props.step != null) {
      this.props.setStep(i);
    } else {
      this.setState({ step: i});
    }
  }

  incStep() {
    this.setStep(this.getStep() + 1);
  }

  decStep() {
    this.setStep(this.getStep() - 1);
  }

  // RENDERING
  renderButtons(opts) {
    const { t } = this.props;

    if (opts.noButtons != null && opts.noButtons.has(this.getStep())) {
      // use this if you rather want to embed buttons
      // in your step section for a specific step
      return null;
    }
    const buttons = [];
    const labelBack = isEmpty(opts.labelBack) ? t('buttons.back') : opts.labelBack;
    const labelNext = opts.labelNext != null && typeof opts.labelNext === 'object' ? opts.labelNext[this.getStep()] : (isEmpty(opts.labelNext) ? 'Next' : opts.labelNext);
    const labelSubmit = isEmpty(opts.labelSubmit) ? t('buttons.submit') : opts.labelSubmit;

    const nextDisabled = opts.nextDisabled != null && typeof opts.nextDisabled === 'object' ? opts.nextDisabled[this.getStep()] : opts.nextDisabled;

    if (this.getStep() < this.state.maxSteps) {
      buttons.push(<Button key="u-signup-next-button" onClick={this.nextButtonClick.bind(this)} variant="contained" color="secondary" disabled={nextDisabled}>{labelNext}</Button>);
    } else {
      buttons.push(<Button key="u-signup-submit-button" onClick={this.submitButtonClick.bind(this)} variant="contained" color="secondary" disabled={nextDisabled}>{labelSubmit}</Button>);
    }
    if (this.getStep() > 1) {
      if (opts.noBackButton == null || !opts.noBackButton.has(this.getStep())) {
        buttons.push(<Button  key="u-signup-back-button" onClick={this.backButtonClick.bind(this)} variant="outlined" size="small" className="default">{labelBack}</Button>);
      }
    }
    return buttons;
  }

  renderStep1() {
    const { t } = this.props;

    let remoteFields = [];
    if (!!this.state.alsoRemote) {
      remoteFields = [
        <TextField
          type="search" variant="outlined" margin="none"
          id={'standard-' + 'timeZones'}
          key={'standard-' + 'timeZones'}
          label={t('search.start.remote.title')}
          placeholder={t('search.start.remote.placeholder')}
          value={this.state.timeZones} onChange={this.stateChange.bind(this,'timeZones')}
          InputProps={{
            startAdornment: (
            <InputAdornment position="start">
              <AccessTime />
            </InputAdornment>
            ),
          }}
        />
      ];
    }

    return [
      <div key="step-01-01" className="infoBox">
        <div className="infoBoxContainer pos-parent">
          <div className="infoBoxText bottom-overlay-fluidity-padding">
            <div>{t('search.start.desc01')}</div>
            <div>{t('search.start.desc02')}</div>
            <div>{t('search.start.desc03')}</div>
          </div>
          <RouterLink to="/about-faq"><Button variant="contained" color="secondary" className="more">{t('nav.findout')} ...</Button></RouterLink>
        </div>
      </div>,
      <TextField
        type="search" variant="outlined" required margin="normal"
        id={'standard-' + 'what'}
        key={'standard-' + 'what'}
        label={t('search.start.what.title')}
        placeholder={t('search.start.what.placeholder')}
        value={this.state.what}
        onChange={this.stateChange.bind(this,'what')}
        InputProps={{
          startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
          ),
        }}
      />,
      <TextField
        type="search" variant="outlined" margin="normal"
        id={'standard-' + 'where'}
        key={'standard-' + 'where'}
        label={t('search.start.where.title')}
        placeholder={t('search.start.where.placeholder')}
        value={this.state.where} onChange={this.stateChange.bind(this,'where')}
        InputProps={{
          startAdornment: (
          <InputAdornment position="start">
            <Satellite />
          </InputAdornment>
          ),
        }}
      />,
      <FormControlLabel
        key="alsoRemote-label"
        control={
          <Checkbox
            disableRipple
            id="alsoRemote"
            key="alsoRemote"
            checked={this.state.alsoRemote ? this.state.alsoRemote : false}
            onChange={this.stateToggle.bind(this,'alsoRemote')}
            //inputProps={{ 'aria-labelledby': "checkbox-list-label-alsoRemote" }}
          />}
        label={t('search.start.remote.option')}
      />,
      ...remoteFields
    ];
  }

  renderStep2() {
    const { t } = this.props;

    let beFirst = [];
    let beFirstSignup = null;

    if (this.props.coveredBeta != null && this.props.coveredBeta.length > 0) {
      if (!!this.state.beFirst) {
        beFirstSignup = <TextareaAutosize
          id={'standard-' + 'about-you'}
          key={'standard-' + 'about-you'}
          aria-label="about-you"
          placeholder={t('signup.step2.option1a.placeholder')}
          margin="normal"
          style={{width: '100%', lineHeight: '16pt'}}
          rowsMin={2}
          rowsMax={8}
          value={this.state.aboutYou}
          onChange={this.stateChange.bind(this,'aboutYou')}
          />;
      }
      beFirst = [
        <div key="step-01-01" className="infoBox">
          <div className="infoBoxContainer pos-parent">
            <div className="infoBoxText bottom-overlay-fluidity-padding">
              <div key="step-02-01">{t('signup.step2.option1a.01')}</div>
              <div key="step-02-02">{t('signup.step2.option1a.02')}</div>
              <div key="step-02-03">{t('signup.step2.option1a.03')}</div>
              <FormControlLabel
                key="beFirst-label"
                control={<Checkbox
                  checked={this.state.beFirst ? this.state.beFirst : false}
                  key="beFirst"
                  id="beFirst"
                  disableRipple
                  onChange={this.stateToggle.bind(this,'beFirst')}
                />}
                label={t('signup.step2.option1a.caption')}
              />
              {beFirstSignup}
            </div>
          </div>
        </div>
      ];
    }

    let updateOptions = null;
    if (this.props.notYetCovered != null && this.props.notYetCovered.length > 0) {
      let updateForSelectionLabel = t('signup.step2.option1b', { also: (beFirst.length > 0) ? t('signup.step2.also'): '', location: (this.props.notYetCovered != null ? this.props.notYetCovered.join(', ') : '')} );
      updateOptions = <FormControlLabel
        key="updateForSelection-label"
        control={<Checkbox
          key="updateForSelection"
          checked={this.state.updateForSelection ? this.state.updateForSelection : false}
          disableRipple
          onChange={this.stateToggle.bind(this,'updateForSelection')}
        />}
        label={updateForSelectionLabel}
      />;
    }
    let updateFullLabel = t('signup.step2.option2', { also: (beFirst.length > 0) ? t('signup.step2.also'): '' });

    return [
      <div key="step-02-01" className="request h">{t('signup.step2.headline')}</div>,
      ...beFirst,
      updateOptions,
      <FormControlLabel
        key="updateFull-label"
        control={<Checkbox
          key="updateFull"
          checked={this.state.updateFull ? this.state.updateFull : false}
          disableRipple
          onChange={this.stateToggle.bind(this,'updateFull')}
        />}
        label={updateFullLabel}
      />
    ];
  }

  renderStep3() {
    const { t } = this.props;

    return [
      <div key="step-03-01"  className="request h">{t('signup.step3.headline')}</div>,
      <SelectiveList id="channel"
        key="channel-list"
        selected={this.props.selectedChannel}
        changeSelection={this.props.channelSelectionChanged}
        selectables={this.state.channels}
        selectableSections = {{
          'signal': [
            <TextField
              id={'signal-id'}
              key={'signal-id'}
              label={t('signup.step3.options.signal.title')}
              type="search" variant="outlined" margin="normal"
              value={this.state.signalId}
              onChange={this.stateChange.bind(this, 'signalId')}
              InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
                ),
              }}
            />
          ],
          'threema': [
            <TextField
              id={'threema-id'}
              key={'threema-id'}
              label={t('signup.step3.options.threema.title')}
              type="search" variant="outlined" margin="normal"
              value={this.state.threemaId}
              onChange={this.stateChange.bind(this, 'threemaId')}
            />
          ]
        }}
      />,
      <p key="step-03-02">{t('signup.step3.options.sources01')} <Link href="https://www.signal.org/" target="_blank" rel="noreferrer">Signal</Link> {t('signup.step3.options.sources02')} <Link href="https://www.signal.org/" target="_blank" rel="noreferrer">signal.org</Link>. {t('signup.step3.options.comingsoon')}</p>
    ];
  }

  renderStep4() {
    const { t } = this.props;

    //TODO add summary of selection
    return [
      <div key="step-04-01" className="request h">{t('signup.step4.headline', { channel: this.state.channels[this.props.selectedChannel].name})}</div>,
      <div key="step-04-02" className="request">{t('signup.step4.desc01')}</div>,
      <TextField
        id={'confirmation-code'}
        key={'confirmation-code'}
        label="Confirmation code"
        type="search" variant="outlined" margin="normal"
        value={this.state.confirmationCode}
        onChange={this.stateChange.bind(this, 'confirmationCode')}
      />
    ];
  }

  renderStep5() {
    const { t } = this.props;

    return [
      <div key="step-05-01" className="request h">{t('signup.step5.headline')}</div>,
      <div key="step-05-02" className="request">{t('signup.step5.desc01')}</div>,
      <div key="step-05-03" className="request">{t('signup.step5.desc02')}</div>
    ];
  }

  render() {
    const { t } = this.props;

    let content = [];

    let buttonDisabled = true;
    let stepSection = null;
    let continueDisabled = true;

    switch (this.getStep()) {
      case 1:
        stepSection = this.renderStep1();
        break;
      case 2:
        stepSection = this.renderStep2();
        break;
      case 3:
        stepSection = this.renderStep3();
        break;
      case 4:
        stepSection = this.renderStep4();
        break;
      case 5:
        stepSection = this.renderStep5();
        break;
    }

    const messages = !this.state.messages ? null : this.state.messages.map (function (item){
      return <span className={item.type}>{item.msg}</span>
    }, this);

    return [
      <div key="usignup-msgs">{messages}</div>,
      stepSection,
      this.renderButtons({
        nextDisabled: {
          1: isEmpty(this.state.what) || (isEmpty(this.state.where) && !this.state.alsoRemote) || (this.state.alsoRemote && isEmpty(this.state.timeZones)),
          2: !this.state.beFirst && !this.state.updateForSelection && !this.state.updateFull,
          3: isEmpty(this.props.selectedChannel) || this.props.selectedChannel < 0
            || isEmpty(this.state[this.state.channels[this.props.selectedChannel].id + 'Id'])
            || this.state[this.state.channels[this.props.selectedChannel].id + 'Id'].length < 4,
          4: isEmpty(this.state.confirmationCode) || this.state.confirmationCode.length < 6 || isNaN(this.state.confirmationCode)
        },
        labelNext: { 1: t('search.start.action'), 2: t('buttons.continue'), 3: t('buttons.reachout'), 4: t('buttons.letsonnect') },
        labelSubmit: t('buttons.newsearch'),
        noBackButton: new Set([5])
      })
    ];
  }

  nextButtonClick(opts) {
    let data = {};
    switch (this.getStep()) {
      case 1:
        data = {
          what: this.state.what, where: this.state.where,
          'also_remote': this.state.alsoRemote, 'time_zones': this.state.timeZones
        };
        break;
      case 2:
        data = {
          updateFull: this.state.updateFull,
          updateForSelection: this.state.updateForSelection,
          beFirst: this.state.beFirst,
          aboutYou: this.state.aboutYou
        };
        break;
      case 3:
        const currentChannel = this.state.channels[this.props.selectedChannel];
        data = {
          'confirmation_channel': (currentChannel != null) ? currentChannel.id : null,
          'confirmation_id': (currentChannel != null) ? this.state[currentChannel.id + 'Id'] : null
        };
        break;
      case 4:
        const currentChannel2 = this.state.channels[this.props.selectedChannel];
        data = {
          'confirmation_channel': (currentChannel2 != null) ? currentChannel2.id : null,
          'confirmation_id': (currentChannel2 != null) ? this.state[currentChannel2.id + 'Id'] : null,
          'confirmation_code': this.state.confirmationCode
        };
        break;
    }
    this.props.continueStepRequest(this.getStep(), data);
    if (this.props.waitForOutsideStepIncrement == null || !this.props.waitForOutsideStepIncrement.has(this.getStep())) {
      this.incStep();
    }
  }

  backButtonClick() {
    // if you want things to be unset / reset
    // when a click on the back button happens implement it in here
    if (this.getStep() == 2) {
        const updatesSelection = {
          updateFull: undefined,
          updateForSelection: undefined,
          beFirst: undefined
        };
        // TODO refactor this ulgy hack that reeks of global/local state inconsistencies
        this.setState(updatesSelection);
        this.props.continueStepRequest(this.getStep(), updatesSelection);
    }
    this.decStep();
  }
  submitButtonClick() {
    // partly reset state of component (keep confirmation id and channel)
    this.setState({
      what: '', where: '', timeZones: '',
      updateFull: false, updateForSelection: false,
      confirmationCode: '',
    });
    this.setStep(1);
  }
}

export default withTranslation()(withStyles(styles)(withLoggedOutLayout(UpdatesSignUpForm)));
