import { connect } from 'react-redux';

import AboutFAQView from './display/about-faq-view';

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

const AboutFAQ = connect(mapStateToProps, mapDispatchToProps)(AboutFAQView);

export default AboutFAQ;
