import { sagas as SessionSagas } from './session';
import { sagas as SearchSagas } from './search';
import { sagas as SignupSagas } from './signup';

const sagas = [
  ...SessionSagas,
  ...SearchSagas,
  ...SignupSagas
];

export default sagas;
