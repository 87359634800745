import { connect } from 'react-redux';

import MainView from './display/main-view';
import { api } from '../api';
import * as Actions from '../actions/index';

const mapStateToProps = state => {
  return {
    username: state.present.username,
    pin: state.present.pin,
    password: state.present.password,
    vault: state.present.vault
  }
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(Actions.direct.session.logout.action()) && dispatch( Actions.direct.snackbar.enqueue.action({ message: 'You are logged out now.', clear: true }) )
  }
};

const Main = connect(mapStateToProps, mapDispatchToProps)(MainView);

export default Main;
