import * as Actions from '../actions/index';
import Pure from 'pure-aid';

function setSearchResult(state, payload) {
  state.coveredBeta = payload.coveredBeta;
  state.notYetCovered = payload.notYetCovered;
  state.searchId = payload.searchId;
  state.updatesSignupStep = 2;
  return state;
}

function setChannelSelection(state, payload) {
  state.selectedChannel = payload.selectedChannel;
  return state;
}

function setUpdatesSignupStep(state, payload) {
  state.updatesSignupStep = payload.step;
  return state;
}

function setUpdateSelection(state, payload) {
  state.updateFull = payload.updateFull;
  state.updateForSelection = payload.updateForSelection;
  state.beFirst = payload.beFirst;
  state.aboutYou = payload.aboutYou;
  return state;
}

const actions = {};

actions[Actions.direct.search.setResult.id] = setSearchResult;
actions[Actions.direct.search.updates.setChannel.id] = setChannelSelection;
actions[Actions.direct.search.updates.setSignupStep.id] = setUpdatesSignupStep;
actions[Actions.direct.search.updates.setSelection.id] = setUpdateSelection;

export { actions };
